$(window).ready(function() {
    $(document).on('gl_action', function (e, action, data, response) {
        if(action !== 'select') return;

        var value = 1;
        switch(data['iso']) {
            /*case 'RU-LEN':
            case 'RU-SPE':
                value = 2;
                break;*/
            case 'RU-SMO':
                value = 4;
                break;
        }
        $.ajax({
            type: 'POST',
            url: cityFields.actionUrl,
            dataType: 'json',
            data: {
                action: 'city/select',
                city: value
            },
            success: function(response) {
                if ( response.success ) {
                    $.get("/",
                        { ms2_action: "cart/clean" },
                        function() {
                            location.reload();
                        }
                    );
                }
                else {
                    console.log(response);
                }
            }
        });
        e.preventDefault();
        return false;
    });

    $('.cfcity__item').on('click', function(e) {
        e.preventDefault();
        if ( $(this).attr('href') ) {
            location.href = $(this).attr('href');
        }
        var value = $(this).data('id');
        $.ajax({
            type: 'POST',
            url: cityFields.actionUrl,
            dataType: 'json',
            data: {
                action: 'city/select',
                city: value
            },
            success: function(response) {
                if ( response.success ) {
                    $.get("/",
                        { ms2_action: "cart/clean" },
                        function() {
                            location.reload();
                        }
                    );
                }
                else {
                    console.log(response);
                }
            }
        });
        e.preventDefault();
        return false;
    });
});